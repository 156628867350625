export const HardCodedTexts = {
  textGuestParkingPromptToCallSupport: {
    characters: 'Notice a problem?',
    name: 'textGuestParkingPromptToCallSupport',
    texts: {
      swe: 'Ser du ett problem?',
      eng: 'Notice a problem?'
    },
    style: {},
    pageName: 'Guest Feature'
  },
  textGuestParkingCallSupport: {
    characters: 'Contact Support.',
    name: 'textGuestParkingCallSupport',
    texts: {
      swe: 'Kontakta Support',
      eng: 'Contact Support'
    },
    style: {},
    pageName: 'Guest Feature'
  }
}
