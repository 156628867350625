import { BiluppgifterVehicle } from '@contracts/types/BiluppgifterVehicle'
import { Owner } from '@contracts/types/VehicleOwner'

import {
  LegacyValitiveOrganization,
  ValitiveOrganization,
  ValitivePersonStripped,
  ValitiveVehicle
} from '@pure/swagger/ValitiveTypesDerived'

export type Vehicle = {
  id: string
  createdAt?: string
  plate?: string
  isEV?: boolean
  owner?: Owner
  ownerId?: string
  updatedAt?: string
  biluppgifter?: BiluppgifterVehicle
  metadata?: {
    updatedAt?: string
    valitiveVehicle?: ValitiveVehicle
    valitivePerson?: ValitivePersonStripped
    valitiveOrganization?: ValitiveOrganization | LegacyValitiveOrganization
    biluppgifter?: BiluppgifterVehicle['data']
  }
  activeSessionId?: string | null
  lastSessionClosedAt?: string
  guestParkingValidUntil?: string
}

export const VehicleFuelType = {
  Electric: 'electric',
  NonElectric: 'non-electric',
  Unknown: 'unknown'
} as const

export type VehicleFuelType = (typeof VehicleFuelType)[keyof typeof VehicleFuelType]
