import { en, LANGUAGES } from '@consts/Locale'

import { Language } from '@pure/emu/Antiloop'

// use web-js use language instead
export const getLanguageCode = (): Language => {
  // TODO WRITE TEST, should be able to render email on server
  if (typeof window === 'undefined') {
    return en
  }
  return getDefaultLanguageCode()
}

export function getDefaultLanguageCode(): Language {
  const defaultLanguageCode = navigator.language?.substr(0, 2) as Language | undefined
  if (!defaultLanguageCode) {
    return en
  }
  if (LANGUAGES.includes(defaultLanguageCode)) {
    return defaultLanguageCode
  }

  return en
}
