import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useEffect, useRef, useState } from 'react'

dayjs.extend(duration)

export const useElapsedTime = (startedAt: string | undefined) => {
  const [timeElapsed, setTimeElapsed] = useState('00:00:00')
  const requestRef = useRef<number | null>(null)
  const lastUpdateTimeRef = useRef<number>(0)

  useEffect(() => {
    if (!startedAt) return

    const startTime = dayjs(startedAt)

    const updateDuration = (timestamp: number) => {
      if (timestamp - lastUpdateTimeRef.current >= 1000) {
        const now = dayjs()
        const timeDifference = now.diff(startTime)

        const formattedDuration = dayjs.duration(timeDifference).format('HH:mm:ss')
        setTimeElapsed(formattedDuration)
        lastUpdateTimeRef.current = timestamp // Update the last update time
      }
      requestRef.current = requestAnimationFrame(updateDuration)
    }

    requestRef.current = requestAnimationFrame(updateDuration)

    return () => {
      if (requestRef.current !== null) {
        cancelAnimationFrame(requestRef.current)
      }
    }
  }, [startedAt])

  return timeElapsed
}
