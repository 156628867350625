import { EUROPE_STOCKHOLM } from '@consts/DateConsts'

import { DefaultSiteSessionLogic } from '@contracts/types/DefaultSite'
import { ParkingSession } from '@contracts/types/ParkingSession'
import { SessionSite } from '@contracts/types/Session'
import {
  Site,
  SitePriceConfigurationItem,
  SitePricingConfiguration,
  SitePricingConfigurationAbsolute,
  SitePricingConfigurationAbsoluteItem
} from '@contracts/types/Site'
import { SiteSessionLogic } from '@contracts/types/SiteSessionLogic'

const WEEK_DAYS = [1, 2, 3, 4, 5]
const WEEKEND_DAYS = [6, 7]

export function getTimezoneForSite(site?: SessionSite) {
  return site?.timezone || EUROPE_STOCKHOLM
}

export const getSessionLogic = ({
  site,
  parkingSession
}: {
  site?: Site
  parkingSession?: ParkingSession
}): Required<SiteSessionLogic> => {
  return { ...DefaultSiteSessionLogic, ...site?.sessionLogic, ...parkingSession?.sessionLogic }
}

export const getSiteCapacity = (site: Site): number => {
  const capacity =
    site?.segments?.reduce((count, segment) => {
      return count + (segment.slots?.length ?? 0)
    }, 0) ?? 0

  return capacity
}

export function isAbsolutePricingConfiguration(
  parking: SitePricingConfiguration
): parking is SitePricingConfigurationAbsolute {
  return parking.type === 'absolute'
}

export function isSitePricingConfigurationAbsoluteItemArray(
  items: SitePriceConfigurationItem[]
): items is SitePricingConfigurationAbsoluteItem[] {
  return items.every((item) => isSitePricingConfigurationAbsoluteItem(item))
}

function isSitePricingConfigurationAbsoluteItem(
  item: SitePriceConfigurationItem
): item is SitePricingConfigurationAbsoluteItem {
  const itemAsAbsolute = item as SitePricingConfigurationAbsoluteItem

  return Boolean(itemAsAbsolute.fromTime && itemAsAbsolute.toTime)
}

export function isWeekdayPricingItem(item: SitePricingConfigurationAbsoluteItem) {
  const hasWeekdays = item?.dayOfWeeks?.some(isWeekday) || isWeekday(item.dayOfWeek)

  return isEverydayPricingItem(item) || hasWeekdays
}

export function isWeekendPricingItem(item: SitePricingConfigurationAbsoluteItem) {
  const hasWeekendDays = item?.dayOfWeeks?.some(isWeekend) || isWeekend(item.dayOfWeek)

  return isEverydayPricingItem(item) || hasWeekendDays
}

function isEverydayPricingItem(item: SitePricingConfigurationAbsoluteItem) {
  return (!Array.isArray(item.dayOfWeeks) || item.dayOfWeeks.length === 0) && !item.dayOfWeek
}

function isWeekend(day?: number) {
  return Boolean(day && WEEKEND_DAYS.includes(day))
}

function isWeekday(day?: number) {
  return Boolean(day && WEEK_DAYS.includes(day))
}
