import { CaptureContext as SentryCaptureContext } from '@sentry/core'
import * as Sentry from '@sentry/react'

import config from './Config'

const environment = typeof window !== 'undefined' ? window.location.hostname : ''

const integrations = typeof window !== 'undefined' ? [Sentry.browserTracingIntegration()] : []

const opts = {
  dsn: config.sentryDsn,
  environment,
  integrations,
  enabled: config.enableSentry,
  tracesSampleRate: 1.0
}

Sentry.init(opts)

export const captureException = Sentry.captureException
export const setUser = Sentry.setUser
export const setTag = Sentry.setTag
export const withScope = Sentry.withScope
export type User = Sentry.User
export type CaptureContext = SentryCaptureContext
