import styled from '@emotion/styled'
import { Alert, Button as MuiButton, IconButton } from '@mui/material'
import dayjs from 'dayjs'

import { SessionEventName } from '@contracts/types/Session'
import { Site } from '@contracts/types/Site'

import { Spacings } from '@guest-parking/enums/Spacings'
import { useFreeGuestParkingTokenPayload } from '@guest-parking/hooks/useFreeGuestParkingToken'
import { GuestParkingSteps, GuestParkingViewProps } from '@guest-parking/libs/GuestParkingHelper'
import { HardCodedTexts } from '@guest-parking/libs/HardCodedTexts'
import Images from '@guest-parking/libs/Images'
import { useSite } from '@guest-parking/libs/ReactQueryHooks'
import { getFigmaText } from '@guest-parking/libs/TextRepository'
import Texts from '@guest-parking/libs/Texts'

import ActiveSessionWidget from '@pay/components/ActiveSessionWidget'
import ActiveSessionWidgetFree from '@pay/components/ActiveSessionWidgetFree'

import { ContactSupport } from '@web-components/components/ContactSupport'

import Box from './Box'
import FigmaImage from './FigmaImage'
import FigmaText from './FigmaText'
import { GuestParkingSiteLogo } from './GuestParkingSiteLogo'
import { Layout } from './Layout'

const GuestParkingInformation = (props: GuestParkingViewProps) => {
  const payload = useFreeGuestParkingTokenPayload()
  const { data: site } = useSite(payload?.siteId || '0')
  const { guestParkingState, onPressBack } = props
  const { session } = guestParkingState.data

  if (session?.parkingSession) {
    const { startedAt } = session.parkingSession

    session.parkingSession.events = [
      ...session.parkingSession.events,
      {
        name: SessionEventName.PARKING_FREE,
        startedAt,
        endedAt: dayjs().endOf('day').format()
      }
    ]
  }

  const errorInformation = `${getFigmaText(HardCodedTexts.textGuestParkingDeclineErrorInformation)} `
  const back = `${getFigmaText(HardCodedTexts.textGuestParkingReturn)} `

  return (
    <Layout>
      <Box fullWidth fullPadding spacing={Spacings.xl} align="flex-start">
        <Box fullWidth align="center">
          <GuestParkingSiteLogo site={site} />
        </Box>
        <StyledGoBackHeader>
          <StyledPositionedIcon>
            <IconButton
              size="small"
              aria-label="close"
              onClick={() => onPressBack(GuestParkingSteps.GUEST_PARKING_CONDITIONS)}
            >
              <FigmaImage imageKey={Images.arrowLeftGrey} />
            </IconButton>
          </StyledPositionedIcon>

          <FigmaText textKey={Texts.textActiveSessionGuestParkingGuestParkingQrLandingHeader} />
        </StyledGoBackHeader>
        {!guestParkingState.hasApprovedTermsAndConditions && (
          <Alert
            severity="error"
            icon={<FigmaImage imageKey={Images.clooseRoundRed} />}
            action={
              <MuiButton
                onClick={() => onPressBack(GuestParkingSteps.GUEST_PARKING_CONDITIONS)}
                variant="text"
                sx={{
                  color: 'rgba(32, 32, 32, 1)',
                  textTransform: 'none'
                }}
              >
                {back}
              </MuiButton>
            }
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              padding: 2,
              border: '1px solid rgba(204, 51, 0, 1)',
              maxWidth: '600px',
              marginBottom: `${Spacings.s}`
            }}
          >
            {errorInformation}
          </Alert>
        )}
        {guestParkingState.hasApprovedTermsAndConditions && (
          <ActiveSessionWidgetFree hideTime session={session} site={site} />
        )}
        {!guestParkingState.hasApprovedTermsAndConditions && session && <ActiveSessionWidget session={session} />}
        <ContactSupport />
      </Box>
    </Layout>
  )
}

const StyledGoBackHeader = styled.section<{ site?: Site }>`
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  width: 100%;
  margin-bottom: ${Spacings.s};
  ${({ site }) => site?.logo && `margin-top: ${Spacings.m};`}
`
const StyledPositionedIcon = styled.div`
  justify-self: start;
`
export default GuestParkingInformation
